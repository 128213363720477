<template>
  <Drawer
    :visible="mpCompareProductsModalService?.visible.value"
    rounded
    hide-footer
    hide-header
    :position="EDrawerPosition.Bottom"
    width="99%"
    :z-index="50"
    class="mm-compare-products-drawer"
    content-class="mm-compare-products-drawer__content"
    wrapper-class="mm-compare-products-drawer-global-wrapper"
    @close="mpCompareProductsModalService?.closeModal()"
  >
    <div class="mm-compare-products-drawer__wrapper">
      <CompareProductsDrawerHeader />

      <ClipLoader v-if="mpCompareProductsModalService?.loadingComparationData.value" />

      <template v-else>
        <div
          v-if="mpCompareProductsModalService?.emptyBannderDisplayed.value"
          class="mm-compare-products-drawer-empty__wrapper"
        >
          <EmptyBanner
            class="mm-compare-products-drawer-empty__banner"
            title-text="Список сравнения пока пуст"
            reason-text="Воспользуйтесь поиском или перейдите в каталог, чтобы найти необходимые товары и добавить их к сравнению"
          >
            <NuxtLink
              class="btn btn-text mm-compare-products-drawer-empty__button"
              to="/categories/"
              @click="mpCompareProductsModalService?.toggleModal()"
            >
              Перейти в каталог
              <SvgIcon
                :src="EIconPath.NavigationMainArrowSvg"
                class="mm-compare-products-drawer-empty__icon"
              />
            </NuxtLink>
          </EmptyBanner>
        </div>

        <template v-else>
          <CompareProductsDrawerCategories class="mm-compare-products-drawer__categories" />

          <ClipLoader v-if="mpCompareProductsModalService?.loaderCharacteristicIsDisplay.value" />

          <template v-else>
            <div
              ref="comparsionHeaderInterceptRef"
              class="mm-compare-products-drawer-comprasion-header__intercept"
            />

            <div
              class="mm-compare-products-drawer-comprasion-header__wrapper"
              :class="{
                'mm-compare-products-drawer-comprasion-header__wrapper--state-stuck': !intersecting
              }"
            >
              <CompareProductsDrawerComprasionHeader
                class="mm-compare-products-drawer-comprasion-header__internal"
                :class="{
                  'mm-compare-products-drawer-comprasion-header__internal--rounded': !mpCompareProductsModalService?.characteristicsDisplayed.value
                }"
              />
            </div>

            <CompareProductsDrawerCharacteristics
              v-if="mpCompareProductsModalService?.characteristicsDisplayed.value"
              class="mm-compare-products-drawer-comprasion__characteristics"
            />

            <button
              class="btn btn-text mm-compare-products-drawer-delete-button"
              @click="mpCompareProductsModalService?.onDeleteCurrentCategory()"
            >
              <SvgIcon
                :src="EIconPath.ActionTrashSvg"
                class="mm-compare-products-drawer-delete-button__icon"
              />
              Удалить список
            </button>
          </template>
        </template>
      </template>
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import Drawer from 'shared/components/Drawer.vue';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import { EDrawerPosition } from 'shared/enums/drawer.enum';
import CompareProductsDrawerHeader from './CompareProductsDrawerHeader.vue';
import CompareProductsDrawerComprasionHeader from './CompareProductsDrawerComprasionHeader.vue';
import CompareProductsDrawerCharacteristics from './CompareProductsDrawerCharacteristics.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import CompareProductsDrawerCategories from './CompareProductsDrawerCategories.vue';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());
const watchSubscription = new WatchSubscription();

const comparsionHeaderInterceptRef = ref();
const observer = ref<IntersectionObserver | undefined>();
const intersecting = ref(true);

watchSubscription.add(
  watch(
    () => comparsionHeaderInterceptRef.value,
    (newHeaderRef) => {
      if (!newHeaderRef) {
        return;
      }

      observer.value = new IntersectionObserver(
        ([element]) => {
          if (element.rootBounds) {
            intersecting.value = element.isIntersecting;
          }
        },
        {
          threshold: 1,
        },
      );

      observer.value.observe(newHeaderRef);
    },
    {
      flush: 'pre',
      immediate: true,
    },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onBeforeUnmount(() => mpCompareProductsModalService?.clear());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer {
  :global(.mm-compare-products-drawer__content) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  :global(.mm-compare-products-drawer-global-wrapper) {
    border-radius: 20px 20px 0 0 !important;
    overflow: hidden
  }

  &__wrapper {
    width: 1600px;
    margin: 0 auto;
    padding-bottom: 62px;
  }

  &-empty {
    &__banner {
      margin-top: 40px;

      :deep(.banner__title) {
        font-weight: 500;
        width: 375px;
      }
    }

    &__wrapper {
      padding: 0 40px;
    }

    &__button {
      padding: 0;
      font-weight: 500;
    }

    &__icon {
      width: 20px;
      height: 20px;
    }
  }

  &__categories {
    margin-top: 20px;
  }

  &-comprasion {
    &-header {
      &__wrapper {
        z-index: 1;
        position: sticky;
        top: 8px;
        transition: box-shadow .15s;
        margin: 20px 8px 0;

        &--state {
          &-stuck {
            box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);

            &::before {
              content: "";
              width: 100%;
              height: 14px;
              display: block;
              position: absolute;
              background-color: white;
              top: -8px;
              z-index: 1;
            }
          }
        }
      }

      &__internal {
        border-top: 1px solid $gray-200;
        border-left: 1px solid $gray-200;
        border-right: 1px solid $gray-200;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        position: relative;
        z-index: 2;

        &--rounded {
          border-radius: 8px;
        }
      }
    }

    &__characteristics {
      border-left: 1px solid $gray-200;
      border-right: 1px solid $gray-200;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-bottom: 1px solid $gray-200;
      overflow: hidden;
      margin: 0 8px;
    }
  }

  &-delete-button {
    margin: 40px 0 0 40px;
    padding: 0;
    color: $red;

    &__icon {
      margin: 0 4px 0 0;

      :deep(path) {
        fill: $red !important;
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer {
    &__wrapper {
      width: 1280px;
    }

    &-delete-button {
      margin: 40px 0 0 32px;
    }

    &-empty {
      &__wrapper {
        padding: 0 32px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer {
    &__wrapper {
      max-width: 100%;
      padding-bottom: 48px;
    }

    &-comprasion {
      &-header {
        &__wrapper {
          margin: 20px 4px 0;
        }
      }

      &__characteristics {
        margin: 0 4px;
      }
    }

    &-delete-button {
      margin: 24px 0 0 20px;
    }

    &-empty {
      &__wrapper {
        padding: 0 20px;

        :deep(.banner__title) {
          font-weight: 500;
          width: 100%;
        }
      }
    }
  }
}
</style>
