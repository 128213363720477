<template>
  <div class="mm-compare-products-drawer-characteristics">
    <AccordionList
      open-multiple-items
      class="mm-compare-products-drawer-characteristics__list"
    >
      <AccordionItem
        v-for="characteristicGroup in mpCompareProductsModalService?.characteristicsToDisplay.value"
        :key="characteristicGroup.name"
        class="mm-compare-products-drawer-characteristics__item"
        default-opened
      >
        <template #summary>
          <div class="mm-compare-products-drawer-characteristics__title">
            {{ characteristicGroup.name }}
          </div>
        </template>
        <template #icon>
          <SvgIcon
            :src="EIconPath.NavigationArrowDown20PxSvg"
            class="mm-compare-products-drawer-characteristics__icon"
          />
        </template>
        <CompareProductsDrawerCharacteristicItems :group="characteristicGroup" />
      </AccordionItem>
    </AccordionList>
  </div>
</template>

<script lang="ts" setup>
import { AccordionList, AccordionItem } from 'vue3-rich-accordion';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import CompareProductsDrawerCharacteristicItems from './CompareProductsDrawerCharacteristicItems.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import SvgIcon from 'shared/components/SvgIcon.vue';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-characteristics {
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;

  &__list {
    :deep(.accordion-item) {
      height: calc(var(--summary-height) + 21px);
      overflow: hidden;
      transition: height .3s ease-in-out;
    }

    :deep(.accordion-item--open) {
      height: calc(var(--summary-height) + var(--content-height) + 21px);
      .mm-compare-products-drawer-characteristics__icon {
        transform: rotate(180deg)
      }
    }
  }

  &__item {
    border: none;

    &:not(&:first-child) {
      margin-top: 20px;
    }

    :deep(.accordion-item__summary::marker) {
      content: "";
    }

    &:not(&:last-child) {
      :deep(.accordion-item__summary) {
        border-bottom: 1px solid $gray-200;
      }

      :deep(.mm-compare-products-drawer-characteristic-items__item:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      :deep(.mm-compare-products-drawer-characteristic-items__item:first-child) {
        border-top: none;
      }
    }

    :deep(.accordion-item__summary) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 32px 20px;
    }
  }

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    color: $text-black;
    margin-right: 4px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    transition: transform .3s ease-in-out;
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-characteristics {
    &__item {
      :deep(.accordion-item__summary) {
        padding: 0 24px 20px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-characteristics {
    padding: 10px 0 0;

    &__list {
      margin: 0 16px;

      :deep(.accordion-item) {
        height: calc(var(--summary-height) + 17px);
      }

      :deep(.accordion-item--open) {
        height: calc(var(--summary-height) + var(--content-height) + 17px);
      }
    }

    &__item {
      &:not(&:first-child) {
        margin-top: 16px;
      }

      :deep(.accordion-item__summary) {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 0 16px;
      }
    }

    &__title {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: $text-black;
    }
  }
}
</style>
