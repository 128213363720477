<template>
  <NuxtLink
    :to="product.slug"
    target="_blank"
    rel="noopen"
    class="mm-compare-products-drawer-comprasion-product-card"
    :class="{
      'mm-compare-products-drawer-comprasion-product-card--state-unavailable': product.unavailable,
    }"
  >
    <div class="mm-compare-products-drawer-comprasion-product-card__head">
      <BaseImg
        class="mm-compare-products-drawer-comprasion-product-card__img"
        :src="product?.images?.[0]?.url || EMPTY_PRODUCT_IMAGES"
      />
      <SvgIcon
        class="mm-compare-products-drawer-comprasion-product-card__icon"
        :class="{
          'mm-compare-products-drawer-comprasion-product-card__icon--state-disabled': mpCompareProductsModalService?.loadingDeleteModel.value
        }"
        :src="EIconPath.ActionTrashSvg"
        @click.stop.prevent="mpCompareProductsModalService?.onDeleteProduct(product)"
      />
    </div>
    <div class="mm-compare-products-drawer-comprasion-product-card__name">
      {{ product?.modelName }}
    </div>
    <div class="mm-compare-products-drawer-comprasion-product-card__price">
      <template v-if="product.unavailable">
        товар не доступен
      </template>
      <template v-else>
        <template v-if="product.minPrice === undefined">
          —
        </template>
        <template v-else>
          <span class="mm-compare-products-drawer-comprasion-product-card__price-from">
            от
          </span>
          &nbsp;{{ StringsHelper.toPriceString(product?.minPrice ?? 0) }}&nbsp;₽
        </template>
      </template>
    </div>
    <div class="mm-compare-products-drawer-comprasion-product-card__offers">
      {{ t(ERuLocales.MarketplaceQuantityNumber, product?.offerCount ?? 0) }}
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import BaseImg from 'shared/components/BaseImg.vue';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { EMPTY_PRODUCT_IMAGES } from 'shared/constants/emptyImages.const';
import { StringsHelper } from 'shared/utils/strings.util';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import { IModelSelectionsModel } from 'models/services/api/modelSelection.model';

defineProps<{
  product: IModelSelectionsModel;
}>();

const { t } = useI18n();

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-comprasion-product-card {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  justify-content: space-between;
  cursor: pointer;
  transition: box-shadow .15s ease-in-out;
  min-width: 255px;
  max-width: 255px;
  text-decoration: none;

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__img {
    max-width: 52px;
    max-height: 52px;
    object-fit: contain;
  }

  &__icon {
    cursor: pointer;
    opacity: 0;
    transition: opacity .15s ease-in-out;

    :deep(path) {
      transition: all .15s;
    }

    &:hover {
      :deep(path) {
        fill: $link;
      }
    }

    &--state {
      &-disabled {
        cursor: wait;

        :deep(path) {
          fill: $text-disabled !important;
        }
      }
    }
  }

  &__name {
    margin-top: 12px;
    flex: 1;
    color: $text-black;
    transition: color .15s;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      color: $link;
    }
  }

  &__price {
    margin-top: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: $text-black;
  }

  &__offers {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $light-green;
  }

  &:hover {
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.12);

    .mm-compare-products-drawer-comprasion-product-card__icon {
      opacity: 1;
    }
  }

  &--state {
    &-unavailable {
      .mm-compare-products-drawer-comprasion-product-card {
        &__img {
          opacity: 0.5;
        }

        &__name,
        &__price,
        &__offers {
          color: $text-disabled;
        }
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-comprasion-product-card {
    min-width: 306px;
    max-width: 306px;
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-comprasion-product-card {
    min-width: 176px;
    max-width: 176px;
    margin: 16px 0;
    padding: 0 16px;

    &__icon {
      opacity: 1;
    }

    &__name {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }

    &__price {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      &-from {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }
    }

    &__offers {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &:hover {
      box-shadow: none;
    }
  }
}
</style>
