<template>
  <div class="mm-compare-products-drawer-characteristic-items">
    <div
      v-for="groupItem in group?.items || []"
      :key="groupItem.id"
      class="mm-compare-products-drawer-characteristic-items__item"
    >
      <Checkbox
        class="mm-compare-products-drawer-characteristic-items__checkbox"
        :model-value="groupItem.checked"
        @update:model-value="mpCompareProductsModalService?.onCheckCharacteristicItemId(groupItem.id, $event)"
      >
        {{ groupItem.name }}
      </Checkbox>
      <CompareProductsDrawerCharacteristicValues
        :key="mpCompareProductsModalService?.selectedCategoryId.value"
        class="mm-compare-products-drawer-characteristic-items__values"
        :group-item="groupItem"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ICompareProductsCharacteristicGroup } from 'models/services/compare-products/compareProductsCharacteristicGroup.model';
import Checkbox from 'shared/components/Checkbox.vue';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import CompareProductsDrawerCharacteristicValues from './CompareProductsDrawerCharacteristicValues.vue';

defineProps<{
  group: ICompareProductsCharacteristicGroup;
}>();

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-characteristic-items {
  display: flex;
  flex-direction: column;

  &__item {
    padding: 16px 20px 16px 32px;
    border-top: 1px solid $gray-200;
    display: flex;
    flex-direction: row;
    transition: background-color .15s;

    &:hover {
      background-color: $light-gray;
    }
  }

  &__checkbox {
    min-width: 247px;
    max-width: 247px;
    margin-right: 8px;

    :deep(.content) {
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-characteristic-items {
    &__item {
      padding: 16px 37px 16px 24px;
    }

    &__checkbox {
      min-width: 281px;
      max-width: 281px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-characteristic-items {
    &__item {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
    }

    &__checkbox {
      min-width: max-content;
      max-width: max-content;
      margin: 0;

      :deep(.checkbox__input) {
        display: none;
      }

      :deep(.content) {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: $light-green;
      }
    }

    &__values {
      margin-top: 8px;
    }
  }
}
</style>
