<template>
  <div class="mm-compare-products-drawer-header">
    <h2 class="mm-compare-products-drawer-header__title">
      Сравнение
    </h2>
    <button
      class="btn btn-secondary mm-compare-products-drawer-header__button"
      @click="mpCompareProductsModalService?.closeModal()"
    >
      Закрыть
      <SvgIcon
        :src="EIconPath.NavigationClose20PxSvg"
        class="mm-compare-products-drawer-header__icon"
      />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { EIconPath } from 'shared/enums/iconPath.enum';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import SvgIcon from 'shared/components/SvgIcon.vue';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 40px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__button {
    padding: 8px 8px 8px 16px;
    height: max-content;
  }

  &__title {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: $text-black;
    margin: 0;
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-header {
    padding: 32px 32px 0;
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-header {
    padding: 20px 20px 0;

    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-compare-products-drawer-header {
    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__button {
      padding: 8px;
      font-size: 0;
    }

    &__icon {
      margin: 0;
    }
  }
}
</style>
