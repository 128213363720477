<template>
  <div class="mm-compare-products-drawer-characteristic-values">
    <div
      class="mm-compare-products-drawer-characteristic-values__list"
      :style="{ transform }"
    >
      <div
        v-for="value in groupItem?.values || []"
        :key="value.productId"
        class="mm-compare-products-drawer-characteristic-values__item"
      >
        {{ value.value ?? '—' }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ICompareProductsCharacteristicItem } from 'models/services/compare-products/compareProductsCharacteristicGroup.model';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import WindowWidthManager from 'shared/services/windowWidth.service';

defineProps<{
  groupItem: ICompareProductsCharacteristicItem;
}>();

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());

const { isLargeDesktop, isDesktop } = WindowWidthManager.getAdaptivaRefs();

const scrollTransitionDuration = ref(mpCompareProductsModalService?.scrollTransitionDuration);

const transform = computed(
  () => isLargeDesktop.value || isDesktop.value
    ? mpCompareProductsModalService?.translateXStyle.value
    : mpCompareProductsModalService?.mobileValuesTranslateXStyle.value,
);
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-characteristic-values {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;

  &__list {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    transition: transform v-bind(scrollTransitionDuration);
  }

  &__item {
    max-width: 239px;
    min-width: 239px;
    word-wrap: break-word;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $text-black;

    &:not(&:last-child) {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-characteristic-values {
    &__item {
      max-width: 290px;
      min-width: 290px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-characteristic-values {
    &__list {
      transition: none;
    }

    &__item {
      max-width: 144px;
      min-width: 144px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      &:not(&:last-child) {
        margin-right: 32px;
      }
    }
  }
}
</style>
