<template>
  <div class="mm-compare-products-drawer-comprasion-products">
    <div
      ref="productsListRef"
      class="mm-compare-products-drawer-comprasion-products__list"
      @scroll="onScrollEvent"
    >
      <div
        ref="swiperWrapperRef"
        class="mm-compare-products-drawer-comprasion-products-swiper__wrapper"
        :style="{
          'transform': mpCompareProductsModalService?.translateXStyle.value
        }"
      >
        <CompareProductsDrawerComprasionProductCard
          v-for="product in mpCompareProductsModalService?.selectedCategoryModels.value || []"
          :key="product.id"
          :product="product"
          class="mm-compare-products-drawer-comprasion-products__item"
        />
      </div>
    </div>
    <div class="mm-compare-products-drawer-comprasion-products__controls">
      <button
        class="mm-compare-products-drawer-comprasion-products-button"
        :disabled="mpCompareProductsModalService?.disabledNextButton.value"
        @click="onScroll(1)"
      >
        <SvgIcon
          class="mm-compare-products-drawer-comprasion-products-button__icon"
          :src="EIconPath.NavigationArrowRight20PxSvg"
        />
      </button>
      <button
        class="mm-compare-products-drawer-comprasion-products-button"
        :disabled="mpCompareProductsModalService?.disabledPrevButton.value"
        @click="onScroll(-1)"
      >
        <SvgIcon
          class="mm-compare-products-drawer-comprasion-products-button__icon"
          :src="EIconPath.NavigationArrowLeft20PxSvg"
        />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import CompareProductsDrawerComprasionProductCard from './CompareProductsDrawerComprasionProductCard.vue';
import SvgIcon from 'shared/components/SvgIcon.vue';
import { EIconPath } from 'shared/enums/iconPath.enum';
import WindowWidthManager from 'shared/services/windowWidth.service';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());

const watchSubscription = new WatchSubscription();
const scrollTransitionDuration = ref(mpCompareProductsModalService?.scrollTransitionDuration);
const disabledActionScrollButtons = ref(false);
const swiperWrapperRef = ref<HTMLDivElement>();
const productsListRef = ref<HTMLDivElement>();

const { isLargeDesktop, isDesktop } = WindowWidthManager.getAdaptivaRefs();

async function onScroll(numberOfProducts: number): Promise<void> {
  if (disabledActionScrollButtons.value) {
    return;
  }

  mpCompareProductsModalService?.scrollProductsTo(numberOfProducts);
}

function calculateStep(): void {
  if (!mpCompareProductsModalService
    || !mpCompareProductsModalService.selectedCategoryModels.value.length) {
    return;
  }

  mpCompareProductsModalService.stepSwiper.value = (swiperWrapperRef.value?.scrollWidth ?? 0)
    / mpCompareProductsModalService.selectedCategoryModels.value.length;

  mpCompareProductsModalService.currentProductCardIndex.value = 0;

  if (productsListRef.value) {
    productsListRef.value.scrollLeft = 0;
  }
}

function onScrollEvent(event: Event) {
  if (!mpCompareProductsModalService) {
    return;
  }

  mpCompareProductsModalService.mobileScrollLeft.value = (event?.target as HTMLDivElement)?.scrollLeft ?? 0;
}

watchSubscription.add(
  watch(
    () => [isLargeDesktop.value, isDesktop.value],
    () => calculateStep(),
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => calculateStep());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-comprasion-products {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;

  &__list {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    flex: 1;
    padding-left: 16px;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 4px;
    padding: 5px 5px 5px 0;
  }

  &-swiper {
    &__wrapper {
      display: flex;
      flex-direction: row;
      transition: transform v-bind(scrollTransitionDuration);
    }
  }

  &-button {
    border-radius: 4px;
    flex: 1;
    background-color: $light-gray;
    border: none;
    outline: none;
    padding: 4px;

    &:hover {
      .mm-compare-products-drawer-comprasion-products-button__icon {
        :deep(path) {
          fill: $link;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      .mm-compare-products-drawer-comprasion-products-button__icon {
        :deep(path) {
          fill: $text-disabled;
        }
      }
    }

    &__icon {
      width: 24px;
      height: 24px;

      :deep(path) {
        transition: fill .15s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-comprasion-products {
    &__controls {
      display: none;
    }

    &__list {
      padding: 0;
      overflow-x: auto;
    }

    &__item {
      &:not(&:last-child) {
        border-right: 1px solid $gray-200;
      }
    }
  }
}
</style>
