<template>
  <div class="mm-compare-products-drawer-categories">
    <div class="mm-compare-products-drawer-categories-list">
      <div
        v-for="category in mpCompareProductsModalService?.categoriesToDisplay.value || []"
        :key="category.id"
        class="mm-compare-products-drawer-categories-list__item "
        :class="{
          'mm-compare-products-drawer-categories-list__item--state-active': category.active,
          'mm-compare-products-drawer-categories-list__item--state-disabled': mpCompareProductsModalService?.disabledCategories.value
        }"
        @click="onSelectCategoryId(category.id)"
      >
        {{ category.name }}
        <span class="mm-compare-products-drawer-categories-list__count">
          {{ category.count }}
        </span>
      </div>
    </div>

    <NuxtLink
      :to="mpCompareProductsModalService?.selectedCategory.value?.slug"
      target="_blank"
      class="btn btn-text mm-compare-products-drawer-categories__button"
    >
      Открыть категорию
      <SvgIcon :src="EIconPath.NavigationOpenNewTabSvg" />
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import { EIconPath } from 'shared/enums/iconPath.enum';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());

async function onSelectCategoryId(categoryId: number): Promise<void> {
  if (mpCompareProductsModalService?.disabledCategories.value) {
    return;
  }

  await mpCompareProductsModalService?.onSelectCategoryId(categoryId);
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 40px;

  &__button {
    padding: 0;
  }

  &-list {
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
    margin-right: 15px;

    &__item {
      padding: 8px 16px;
      color: $text-black;
      background-color: $light-gray;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      white-space: nowrap;
      transition: background-color .15s ease-in-out,
        color .15s ease-in-out,
        opacity .15s ease-in-out;

      &--state {
        &-active {
          background-color: $text-dark-green;
          color: $text-white;

          .mm-compare-products-drawer-categories-list__count {
            color: $text-white;
          }
        }

        &-disabled {
          opacity: .5;
          cursor: not-allowed;
        }
      }
    }

    &__count {
      color: $light-green;
      margin-left: 8px;
      transition: color .15s ease-in-out;
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-categories {
    padding: 0 32px;
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-categories {
    padding: 0 20px;

    &-list {
      &__item {
        padding: 6px 8px;
      }
    }

    &__button {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-compare-products-drawer-categories {
    display: flex;
    flex-direction: column;

    &-list {
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
      padding-bottom: 6px;
      margin: 0;
    }

    &__button {
      margin-top: 12px;
    }
  }
}
</style>
