<template>
  <div class="mm-compare-products-drawer-comprasion-header">
    <div
      class="mm-compare-products-drawer-comprasion-header__controls"
    >
      <template v-if="isDeskopControls">
        <div class="mm-compare-products-drawer-comprasion-header__text">
          Показывать:
        </div>
        <RadioGroup
          v-if="mpCompareProductsModalService"
          v-model="mpCompareProductsModalService.selectedComprasionType.value"
          :buttons="mpCompareProductsModalService.comprasionTypesRadioButtons.value || []"
          column
          is-disable-emit-value-by-btn-list-change
          class="mm-compare-products-drawer-comprasion-header__radio-buttons"
        />
      </template>
      <template v-else>
        <ToggleSwitch
          :model-value="checkedToggleSwitch"
          :disabled="mpCompareProductsModalService?.disabledToggleSwitch.value"
          class="mm-compare-products-drawer-comprasion-header__toggle-switch"
          @update:model-value="onToggleSwitch"
        >
          Показывать только различия
        </ToggleSwitch>
      </template>
    </div>
    <CompareProductsDrawerComprasionProducts
      :key="mpCompareProductsModalService?.selectedCategoryId.value"
      class="mm-compare-products-drawer-comprasion-header__products"
    />
  </div>
</template>

<script lang="ts" setup>
import RadioGroup from 'shared/components/RadioGroup.vue';
import { MpCompareProductsModalService } from 'services/compare-products/mpCompareProductsModal.service';
import CompareProductsDrawerComprasionProducts from './CompareProductsDrawerComprasionProducts.vue';
import WindowWidthManager from 'shared/services/windowWidth.service';
import ToggleSwitch from 'shared/components/ToggleSwitch.vue';
import { ECompareProductsComprasionType } from 'enums/services/compare-products/compareProductsComparsionType.enum';

const mpCompareProductsModalService = inject<MpCompareProductsModalService>(MpCompareProductsModalService.getServiceName());

const { isLargeDesktop, isDesktop } = WindowWidthManager.getAdaptivaRefs();

const isDeskopControls = computed(() => isLargeDesktop.value || isDesktop.value);

const checkedToggleSwitch = computed(
  () => mpCompareProductsModalService?.selectedComprasionType.value === ECompareProductsComprasionType.OnlyDifferences,
);

function onToggleSwitch(checked: boolean): void {
  if (!mpCompareProductsModalService) {
    return;
  }

  mpCompareProductsModalService.selectedComprasionType.value = checked
    ? ECompareProductsComprasionType.OnlyDifferences
    : ECompareProductsComprasionType.All;
}
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-compare-products-drawer-comprasion-header {
  padding: 0 0 0 32px;
  border-bottom: 1px solid $gray-200;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $white;

  &__controls {
    margin-top: 20px;
    margin-right: 46px;
  }

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $text-black;
  }

  &__radio-buttons {
    margin-top: 20px;

    :deep(.radio-group__item__text) {
      white-space: nowrap;
    }

    :deep(.radio__input) {
      span {
        transition: background-color .15s;
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-compare-products-drawer-comprasion-header {
    padding: 0 0 0 24px;

    &__controls {
      margin-right: 81px;
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-compare-products-drawer-comprasion-header {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;

    &__controls {
      flex: 1;
      padding: 20px 16px;
      border-top: 1px solid $gray-200;
      margin: 0;
    }

    &__products {
      flex: 1;
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-compare-products-drawer-comprasion-header {
    &__controls {
      padding: 16px;
    }
  }
}
</style>
